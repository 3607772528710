@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/modules/navigation/navigation.scss';
@import '../../node_modules/swiper/modules/pagination/pagination.scss';

/* Gallery Overrides */

.swiper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    border-radius: 7px;
    overflow: hidden;
}

.swiper-slide picture {
    display: block;
    width: 100%;
    // height: 100%;
    object-fit: cover;
}

.gallery-swiper {
    height: 50vh;
    width: 100%;
}

.thumb-swiper {
    height: 20vh;
    box-sizing: border-box;
    padding: 10px 0;

    .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }
    .swiper-slide-thumb-active {
        opacity: 1;
    }
}

.swiper-button-prev, .swiper-button-next {
    top: calc(50% - var(--swiper-navigation-size) / 3.75);
    width: calc(var(--swiper-navigation-size) / 3.75);
    height: calc(var(--swiper-navigation-size) / 3.75);
    padding: calc(var(--swiper-navigation-size) / 3.75);
    border-radius: 50%;
    background-color: $eb-light;
    opacity: .8;
    margin: 0 calc(var(--swiper-navigation-size) / 3.75);

    &::after {
        font-size: calc(var(--swiper-navigation-size) / 3);
        color: $eb-blue;
    }

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    .swiper-button-prev, .swiper-rtl, .swiper-button-next, .swiper-rtl .swiper-button-prev, .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        display: none;
    }
}