.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,.5);
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .splash-inner {
        width: 50vw;
    }

    h1 {
        color: $eb-gold;
        font-size: 5vw;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
    }

    .splash-content {
        color: #fff;
        text-align: center;
        margin: 1em 0 2em;
    }

    button {
        opacity: 0;
        position: relative;
        top: 20vh;
        display: block;
        margin: 0 auto;
        // background-color: $eb-dk-gold;
        border: 1px solid $eb-gold;
        font-weight: 700;
        padding: 12px 36px;
        text-align: center;
        color: $eb-gold;
        background-color: transparent;
        text-decoration: none;
        font-size: 14px;
        line-height: 21px;
        text-shadow: none;
        box-shadow: none;
        border-radius: 7px;
        box-sizing: border-box;
        transition: all .3s ease-in-out .3s;

        &.active {
            opacity: 1;
            top: 0;
        }

        &:hover {
            border-color: $eb-blue-2;
            color: white;
            background: $eb-blue-2;
            cursor: pointer;
        }
    }
}

body.has-splash {
    overflow: hidden;
        #about, #featurenav, #features, #map .legend {
            display: none;
            transition: all .5s;
    }
    #features.collapseNav {
        left: 50%;
    }
    .toggle-nav {
        display: none;
    }
    .toggle-list {
        display: none;
    }
}

.mapboxgl-marker {
    padding-bottom: 200vh;
    &.loaded {
        padding-bottom: 0;
        transition: padding-bottom .5s ease-out .5s;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 


    .has-splash #features .nav {
        top: 64px;
        transition: all .5s .5s;
    }

    .modal {

        .mark {
            display: none;
        }
        .splash-inner {
            width: 90vw;
            margin: 0 auto;
        }
        h1 {
            font-size: 56px;
        }
    }
}