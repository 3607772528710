// _welcome.scss

// Classes include the expanded place view

#featurenav.expandFNV {
    pointer-events: visible;
    opacity: 1;
    width: 100%;
    left: 0;
    // transition: all 0 ease;
    //
} 

#features.expandABT {

    .welcome, .nav {
        display: none;
    }

    .feature {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        // padding-top: 5vh !important;

        h3 {
            margin: 80px 0 12px !important;
            border-bottom: 0;
            background-color: transparent !important;
            font-size: 40px !important;
            // important needed to override the use case of expanding the about panel from the collapsed list view.
        }

        span.blurb {
            display: none !important;
        }

        .description, .copyblock {
            display: block !important;
            margin-bottom: 16px;
        }

        .featureblock {
            width: 45%;
            margin-left: 5%;
            display: flex;
            margin-top: 64px;


            .description {
                height: calc(70vh - 294px);
                overflow: scroll;
                padding: 0 40px 40px 0;
                position: relative;
                border-bottom: 1px solid $dk-gray;
                scrollbar-width: thin; /* can also be normal, or none, to not render scrollbar */
                scrollbar-color: currentColor transparent; /* foreground background */
                object-fit: cover;
                -webkit-mask-image: linear-gradient(to top, transparent 0, black 3em);
                mask-image: linear-gradient(to top, transparent 0, black 100px);
                
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 7px;
                    height: 0;
                }
                  
                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: rgba(0, 0, 0, .3);
                    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
                }

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

            }
        }
    }

    .gallery {
        display: block;
        width: 50%;
        margin-top: 64px;
        pointer-events: visible;
    }

    .description > :not(:first-child) { 
        display:block;
    }

    .heroimg {display: none;}

    .prev, .next {display: block !important;}

}

// Media Queries


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    #features {
        &.expandABT {
            left: 0;
            top: 0;
            height: 100vh;
            .feature {
                min-height: auto;
                height: auto;
                margin-top: 0;
                padding-top: 0;
                h3 {
                    margin: 1em 0 0 !important;
                    font-size: 24px !important;
                    line-height: 1;
                }
                .featureblock {
                    width: 100%;
                    margin: 0;
                    .description {
                        height: auto;
                        padding: 0;
                        -webkit-mask-image: none;
                        mask-image: none;
                        border: none;
                    }
                    .prev-next-buttons {
                        display: none !important;
                    }
                }
            }
            
            .gallery {
                width: 80%;
                margin-top: 0;
                .thumb-swiper {
                    display: none;
                }
            }
        }
    }
}



