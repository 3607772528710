// _base.scss
@use "sass:math";

html {
    //scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    @extend %font-rustica;
    font-weight: 500;
    font-style: normal;
}

p {
    margin: 0;
    padding: 8px 8px 8px 0;
    letter-spacing: 3%;
    line-height: 24px;
    color: $dk-gray;
    font-size: 16px;
    line-height: 1.5;
}

a {
    font-weight: 700;
    color: $eb-blue-2;
    &:hover {
      color: $eb-blue;
    }
}

h1, h2, h3 {
  @extend %font-mr-eaves;
  color: $eb-blue;
}

h4 {
    @extend %font-rustica;
    font-weight: 700;
    color: $eb-blue;
}

.btn {
    display: inline-block;
    background: $eb-blue-2;
    border: none;
    font-weight: 700;
    padding: 12px 36px;
    text-align: center;
    color: white;
    text-decoration: none;
    font-size: 10px;
    line-height: 14px;
    text-shadow: none;
    box-shadow: none;
    border-radius: 7px;
    box-sizing: border-box;
    &:hover {
        background: $eb-blue;
        color: white;
    }
    &.cta {
        // width: 100%;
        background: $eb-blue-2;
        border: none; 
        color: white;
        &:hover {
          background: $eb-blue;    
        }       
    }
}

// INTERACTIVES //

$total-arrows: 3;
$arrow-line-length: 25px;
$arrow-line-width: 4px;

// arrow animtion + choose direction
@mixin arrow-transitions($rot: 0deg) {
  transform: translate(-50%, -50%) rotateZ($rot);
}

// arrows
.arrow-container {
  display: block;
  width: 50px;
  height: 50px;  
  position: relative;
  top: 50%;
  left: 50%;
  @include arrow-transitions(0deg);
  &:hover {
    cursor: pointer;
    .arrow {
      top: 50%;
      &:before {
        @include arrow-transitions(-30deg);
      }
      &:after {
        @include arrow-transitions(30deg);
      }      
    }
  }
}
.arrow {
  position: absolute;
  left: 50%;
  transition: all .1s ease;
 
  &:before,
  &:after {
    transition: all .1s ease;
   
    content: '';
    display: block;
    position: absolute;
    transform-origin: bottom right;
    background: $eb-gold;
    width: $arrow-line-width;
    height: $arrow-line-length;
    border-radius: 10px;
    transform: translate(-50%, -50%) rotateZ(-45deg);
  }
  &:after {
    transform-origin: bottom left;
    transform: translate(-50%, -50%) rotateZ(45deg);
  }
  @for $i from 1 through $total-arrows {
    &:nth-child(#{$i}) {
      opacity: 0.3 * $i;
      top: 15 + (100% * math.div($i, 5));
    }
  }
}

// MAP STYLES //

.logo {
    display: block;
    position: absolute;
    z-index: 1000;
    min-width: 200px;
    height: $sidespace;
    margin: 16px $sidespace;
}

.mark {
  display: none;
}

#map {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 60%;
    transition: width .1s ease, height .1s ease, transform .1s ease;
    .mapboxgl-canvas {
        width: 50vw;
        transition: width .1s ease, height .1s ease, transform .1s ease;
    }
    &.expandMap {
        width: 100%;

        .toggle-list {
          display: none;
        }

        .mapboxgl-canvas {
            width: 100vw;
            transition: width .1s ease, height .1s ease, transform .1s ease;
        }
    }
} 

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    text-align: center;
    h3, p, span {
      padding: 0;
      font-weight: 700;
      margin: 0px;
      font-size: 11px;
      line-height: 1.5;
    }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
  .logo {
    display: none;
  }

  .mark {
    display: block;
    position: fixed;
    z-index: 1000;
    height: 32px;
    margin: 16px;
    top: 0;
  }

}