// _features.scss

.content {
    min-height: 100vh;
    padding-bottom: 40vh;
}

#features {
    position: absolute;
    width: 40%;
    left: 0%;
    margin-left: 60%;
    overflow-y: scroll;
    background-color: $eb-light;
    transition: all .1s ease;
    z-index: 999;

    .nav {
        position: fixed;
        top: 0; /* fix strange bug in mobile */
        height: $topspace;
        background-color: $eb-light;
        width: 100%;

        .toggle-list, .toggle-nav, .toggle-about {
            color: $eb-blue-2;
            &:hover {
                cursor: pointer;
            } 
        }

        .toggle-about {
            text-transform: uppercase;
            line-height: 64px;
            font-size: 16px;
            padding: 0 32px;
            top: 0;
            content: "About Us";
            font-weight: bold;
            position: fixed;
        }

        .toggle-list, .toggle-nav {
            position: fixed;
            @extend %font-eb-marker; 
            top: 0;
            font-size: 24px;
            line-height: 64px;
            z-index: 1000; 
        }

        .toggle-list {
            left: calc(100vw - 60px); 
            &:before {
                content: "\e901";
            }
        }

        .toggle-nav {
            left: calc(60vw - 60px);
            color: $eb-light;
            &:before {
                content: "\eb48";
            }
            &:hover {
                color: white;
            } 
        }
    }

    &.collapseNav {
        .toggle-list {
            display: none;
        }
        .toggle-nav {
            left: calc(100vw - 60px);            
        }
    }

    span.blurb {
        display: none;
    }

    &.listNav {
        .content {
            padding-bottom: 0;
        }
        .welcome {
            min-height: auto;
            padding-bottom: 16px;
            p, .scroll {display: none;}
        }
        div.feature {
            padding: 0 32px;
            margin: 0;

            .featureblock, .heroblock {
                height: auto;
            }

            .description, .heroimg, .copyblock, .prev, .next {
                display: none;
            }

            @at-root .expandBlurb#{&} .prev-next-buttons {
                display: flex;
            }

            h3 {
                font-size: 18px;
                padding: 12px 0 12px 12px;
                margin: 0;
                border-top: none !important;
            }

            a {
                pointer-events: auto;
            }

            &.active {
                span.blurb {
                    float: right;
                    display: block;
                    padding: 13px;
                    height: 13px;
                    width: 13px;
                    font-size: 13px;
                    transform: rotate(45deg);
                    color: $eb-blue-2;
                    &:hover {
                        cursor: pointer;
                    }
                }

                @at-root .expandBlurb#{&} h3 {
                    margin-bottom: 24px;
                }

                @at-root .expandBlurb#{&} span.blurb {
                    transform: rotate(0deg);
                }

                a.list-nav h3 {
                    background-color: $eb-gold;
                    border-color: $eb-gold;
                }

            }
        }

        .toggle-list:before {
            content: "\e900";
        }
    
    }

    &.collapseNav {
        left: calc(40% - 12px);
        transition: all .1s ease;
       
        .toggle-nav {
            right: calc(25vw + $sidespace);
            &:before {
                color: $eb-light;
                content: "\f053";
            }
        }
    }

    &.expandABT {
        left: -60%;
        width: 100%;
        transition: all .1s ease;
    }

    &.expandBlurb {
        div.active {
            .description, .copyblock {
                display: block;
            }
            .copyblock {
                // margin: 18px 12px;
            }
            h4, .icon, .collateraltype {
                // display: none;
            }
        }
    }
}

div.welcome {
    min-height: 50vh;
    padding: 10vh $topspace 0 $sidespace;
    display: flex;
    flex-direction: column;

    h2 {
        font-size: 50px;
        line-height: .8;
        font-size: 4vw;
        text-transform: uppercase;
        b {
            color: $eb-gold;
            clear: both;
        }
    }

    .intro {
        flex: 8
    }

    .scroll {
        flex: 1;
        margin-top: auto;
        display: block;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        margin: 64px auto;
        span {
            display: block;
            font-weight: bold;
        }
    }
}

#featurenav {
    pointer-events: none;
    opacity: 0;
    width: 100%;
    height: 64px;
    position: fixed;
    background-color: $eb-light;
    z-index: 1000;
    left: 50%;
    transition: all .2s ease;
   

    .close {
        display: block;
        flex-basis: 100%;
        height: $topspace;
        text-align: right;
        text-decoration: none;
        margin: 0 $sidespace;
        cursor: pointer;
        span {
            line-height: $topspace;
            font-size: $iconsize;
        }
    }

}

div.feature {
    width: calc(100% - 64px);
    padding: 64px 32px;
    margin: auto;

    img {
        width: 100%;
        border-radius: 7px;
    }

    .close {
        display: none;
    }

    h3 {
        margin: 16px 0;
        border-bottom: 1px solid $eb-gold;
        padding: 0 12px 12px;
        font-size: 42px;
        text-transform: uppercase;
        line-height: .83;
        &:hover {
            border-bottom-color: $eb-dk-gold;
        }
    }

    a.list-nav {
        text-decoration: none;
        pointer-events: none;
    }

    .featureblock {
        .heroblock {
            .heroimg {
                height: 30vh;
                background-size: cover;
                background-position: 50% 50%;
                border-radius: 7px;
                // Gradient styles if overlapping text
                // &:before {
                //     width: 100%;
                //     display: block;
                //     height: 100%;
                //     background: linear-gradient(5deg, rgba($eb-light, .75) 0%, rgba($eb-light, 0) 50%);
                //     content: '';
                // }
            }
        }

        .copyblock {
            overflow: hidden;
            margin: 12px;

            h4, .icon, .collateraltype {
                font-size: 12px;
                letter-spacing: 0.03em;
                margin: 0;
                color: $eb-blue;
                text-transform: uppercase;
                display: inline;
                font-style: normal;
            }

            h4 span:before, .icon {
                color: $eb-gold;
            }

            h4 {
                margin-right: 20px;
            }

            .description {
                margin: 12px 0;
            }

            .description > :not(:first-child) { 
                display:none;
            }

            .btn {
                margin: 6px 12px 16px 0;
                display: block;
            }

            

        }
    }

    .gallery { display: none; }

    .prev-next-buttons {
        display: flex;
        a.btn {
            flex: auto;
            flex:1 1 auto;
            margin:12px 6px;
        }
        .close {display: none;}
    }

    &.active .featureblock .copyblock {
        .btn {
            // margin: 0 0 36px;
        }
        .prev, .next {
            // display: none;
        }
    }

    &.expandABT .featureblock .copyblock {
        .prev, .next {
            display: block !important;
        }
    }

    &:last-child {
        border-bottom: none;
        margin-bottom: 200px;
    }
}

div.point {
    display: none;
}

.expandABT .boilerplate {
    display: none;
}

.boilerplate {
    position: fixed;
    padding: 0;
    width: 40%;
    background: $eb-blue;
    color: rgba(255, 255, 255, .5);
    bottom: 0;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 12px;
    line-height: 32px;

    a {
        color: rgba(255, 255, 255, .5);
        padding: 0 15px 0 0;
        text-decoration: none;
        &:hover {
            color: #fff;
        }
    }
    
    span {
        padding-left: 15px;
        line-height: 32px;
    }

    .follow {
        flex: .7;
        padding: 0 0 0 32px;
    }

    .legal {
        font-size: 18px;
        display: flex;
        flex-direction: row-reverse;
        flex: 1;
        padding: 0 32px 0 0;
    }

    .copyright {
        font-size: 10px;
    }

}

// Media Queries


// XL devices 
@media (min-width: 1700px) {   
    #features.expandABT {

        .gallery {
            width: 60% !important;
        }

        .feature .featureblock {
            width: 35% !important;
            margin-left: 5%;
            .copyblock {
                width: 90%;
                .description {
                    padding-right: 10%;
                }
            }
        }
    }
}

// Medium devices
@media (max-device-width: 1440px) { 

    #features {
        width: 50%;
        margin-left: 50%;

        &.expandABT {
            left: -50%;
        }

        .nav .toggle-nav {
            left: calc(50vw - 60px);
        }

    }
}


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 

    p {
        font-size: 16px;
    }

    #featurenav {
        a {
            color: $eb-light;

            &.close {
                color: $eb-blue-2;
            }
        }

        &.expandFNV {
            background-color: $eb-light;
            .mark {
                // display: none;
            }
        }
    }

    #features {
        width: 100%;
        // height: calc(100vh - 64px);
        height: 100vh;
        margin-left: 0;
        bottom: 0;

        .nav {
            background-color: $eb-blue;
            // margin-top: -64px;            
            a {
                // display: none;
                &.reset-map {
                    display: block;
                    width: 60px;
                    height: 60px;
                }
            }
            
            .toggle-list {
                left: calc(100vw - 40px);
                color: $eb-light;
            }
            .toggle-about {
                left: calc(100vw - 192px);
                position: fixed;
                color: $eb-light;
                top: 0;
            }
            .toggle-nav {
                display: none;
            }
        }

        .welcome {
            // display: none;
            height: auto;
            min-height: auto;
            padding: 72px 32px 32px;
            background: $eb-blue;
            margin-bottom: 32px;
            h2, p {
                color: $eb-light;
            }
            h2 {
                font-size: 30px;
            }
            .scroll {
                margin-top: 64px;
                display: none;
                // removing scroll on mobile for now per feedback
            }
        }

        .content {
            overflow: hidden;
            padding-top: 64px;
            padding-bottom: 0;
        }

        .feature {
            padding: 0;
            width: calc(100% - 32px);
            height: auto;
            min-height: auto;

            .featureblock {
                height: auto;
                width: 100%;

                .heroblock {
                    height: auto;
                }
                
                h3 {
                    font-size: 16px !important;
                    padding: 12px 0 12px 12px !important;
                    margin-bottom: 14px;
                    line-height: 1.25;
                }
                
                h4 {
                    font-size: 13px;
                }

                .copyblock {
                    margin-bottom: 56px;
                }

                .cta {
                    width: 100%;
                }
            
            }

            .gallery-swiper {
                height: auto;
                overflow: visible;
            }

            &:last-child {
                margin: 0;
            }
            
        }
        .heroimg, .prev, .next {
            display: none !important;
        }

        &.listNav { 
            height: 40vh;
            .nav {
                background-color: transparent;
                .mark {
                    display: none;
                }
                .toggle-about {
                    display: none;
                }
                .toggle-list {
                    color: transparent;
                }
            }
            .welcome {
                padding: 20px;
                display: none;
                .scroll {
                    display: none;
                }
            }
            div.feature {
                width: calc(100% - 16px);
                margin: auto;
                padding: 0;
            }
        }

        &.collapseNav {
            left: 0;
            top: 100vh;
            .nav .toggle-nav {
                left: calc(100vw - 60px);
                &::before {
                    content: "\ed6c"; 
                }
            }
        }

        &.expandABT {
            left: 0;
            top: 0;
            height: 100vh;

            .feature {
                min-height: auto;
                height: auto;
                margin-top: 2em;
                .featureblock {
                    .cta {
                        display: none;
                    }
                }
            }
        }
    }

    .boilerplate {
        position: initial;
        padding: 18px 0;
        text-align: center;
        width: 100% !important;
        display: block;

        a {
            padding: 0 12px;
        }

        span {
            display: inline;
            padding: 6px 10px;
        }

        .follow, .legal {
            width: 100%;
            display: block;
            padding: 0;
        }

        .copyright {
            display: block;
        }

    }

}