// _map.scss

.mapboxgl-popup-content {
    h3, p, span { 
        font-family: rustica, sans-serif;
        color: $eb-blue;
        text-transform: capitalize;
    }
    h3 { 
        color: $eb-blue-2;
    }
    span {
        color: $eb-gold;
        font-size: 2em; 
    }
}
#map {
    z-index: 0;

    .toggle-list {
        position: fixed;
        top: 0;
        height: $topspace;
        cursor: pointer;
        position: fixed;
        @extend %font-eb-marker;
        font-size: 24px;
        line-height: 64px;
        z-index: 1000; 
        left: calc(100vw - 40px); 
        &:before {
            content: "\e900";
        }
        color: $eb-light;
        z-index: 1000;
    }

    .legend {
        z-index: 1000;
        position: fixed;
        width: 60%;
        background: #192168;
        color: rgba(255, 255, 255, 0.5);
        bottom: 0;
        display: flex;
        font-size: 12px;
        line-height: 32px;
        .key {
            @extend %font-eb-marker;
            padding-left: 10px;
        }
        span {
            padding-left: 5px;
            &::before {
                padding-right: 5px;
                color: $eb-gold;
                opacity: .8;
            }
        }
        span:nth-child(3) {
            &::before {
                color: #bababa;
            }
        }
    }
    &.expandMap .legend {
        width: calc(100vw - 12px);
    }
}

// Safari compatibility
#map-shim {
    position: fixed;
    z-index: 1;
    background-color: white;
    width: 40%;
    height: 100vh;
    right: 0;
}
#map.expandMap + #map-shim {
    width: 0;
}

// Medium devices
@media (max-device-width: 1440px) { 

    #map .legend, .boilerplate {
        width: 50%;
    }

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 

    #map {
        width: 100%;
        height: 60vh;
        &.expandMap {
            height: 100vh;
        }
        .legend {
            top: calc(60vh - 32px);
            top: 100vh;
            width: 100% !important;
            span:nth-child(n+3) {
                display: none;
            }
        }
    }
    
    #map-shim {
        display: none;
    }

}