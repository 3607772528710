// variables.scss

// colors
$eb-blue: #192168;
$eb-blue-2:#1632d8;
$eb-gold: #FDBC18;
$eb-dk-gold: #C6930A;
$eb-light: #fff;
$lt-gray: #afafaf;
$dk-gray: #1a1a1a;

// units
$topspace: 64px;
$sidespace: 32px;
$iconsize: 24px;