    // _about.scss

    

#about {
    display: none;
    z-index: 1000;
    position: fixed;
    background: $eb-light;
    height: 100vh;
    overflow: scroll;

        .about-blurb {
            // padding: 64px 0 0 32px;

            display: flex;

            h1 {
                line-height: 0.8;
                margin-bottom: 1.3vw;
                font-size: 3.6vw;
                text-transform: uppercase;
                b {
                    color: $eb-gold;
                }
            }
            
            div:first-child {
                background-color: $eb-blue;
                flex: .2;
                min-width: 264px;
                padding: 100px 0 0 40px;
                margin: 0;
            }

            div:nth-child(2) {
                padding: 72px 5vw;
                flex: .5;
                background-color: $eb-light;
                .blurb {
                    padding-bottom: 32px;
                    margin-bottom: 24px;
                    border-bottom: 1px solid $eb-dk-gold;
                }
                .toggle-about, .btn-contact {
                    position: relative;
                    left: auto;
                    font-size: 10px;
                    min-width: 200px;
                    &::before {
                        font-size: 10px;
                        @extend %font-eb-marker;
                    }
                }

                
            }

            div:nth-child(3) {
                background: url(/assets/images/eagle-bg.png) no-repeat 50% $eb-blue;
                background-size: cover;
                height: calc(100vh - 136px);
                flex: .3;
                padding-top: 136px;
                p {
                    color: $eb-light;
                    line-height: 1;
                    font-size: 11px;
                    margin: 0;
                    padding: 11px 0 0 40px;
                    opacity: .7;

                    &.follow {
                        margin-bottom: 21px;
                        a {
                            color: $eb-light; 
                            text-decoration: none;
                            margin-right: 24px;
                        }
                    }

                    &.legal {
                        margin-top: 28px;
                        padding-top: 28px;
                        border-top: 1px dotted rgba(255,255,255,.2);
                    }

                    .icon-FDIC_Member, .icon-Equal_Housing_Lender {
                        font-size: 24px;
                        margin-right: 28px;
                    }

                }

            }

            .toggle-about::before {
                content: "\f104";
            }

        }

        .toggle-about, .btn-contact {
            /* Close button */
            left: calc(100vw - 60px);
            @extend %font-rustica;
            color: $eb-light;
            top: 16px;
            position: fixed;
            line-height: 1;
            font-size: 24px;
            z-index: 1000; 
            &:hover {
                cursor: pointer;

            } 
            &::before {
                @extend %font-eb-marker; 
            }
        }

        .toggle-about::before {
            content: "\ed6c";
        }
    
        &.openABT {
            display: block;
            background-color: $eb-blue;
        }

}

// Small devices
@media (max-width: 767.98px) { 
    #about {
        .about-blurb {
            div:first-child {
                background-color: $eb-light;
            }

            h1 {
                font-size: 1.75em;
            }
        }

        &.openABT .logo { 
            display: none;
        }
    }
}


// Small-medium devices
@media (max-width: 1023.98px) { 
    #about {

        &.openABT {
            background: url(/assets/images/eagle-bg.png) no-repeat 50% 117% $eb-blue;
            background-size: contain;
        }

        .toggle-about {
            /* Close button */
            left: calc(100vw - 40px);
            top: 16px;
            &::before {
                font-size: 20px;
                color: $eb-gold;
            }
        }

        .about-blurb {
            display: block;

            div:first-child {
                height: 64px;
                padding: 0;    
                background-color: $eb-blue;
            }

            div:nth-child(2) {
                height: auto;
                padding: 36px 36px 72px;
                .toggle-about, .btn-contact {
                    width: 100%;
                }
            }

            div:nth-child(3) {
                background-color: transparent;
                background-image: none;
                padding: 36px;
                p {
                    padding-left: 0;
                }
            }

        }
    }
}

// XL devices 
@media (min-width: 1700px) {   
    
    p {
        font-size: 18px;
    }

    #about .about-blurb div:nth-child(3) p {
        font-size: 14px;
        padding: 14px 0 0 40px;
    }

}