// fonts.scss

/* Fonts import */
@import url("https://use.typekit.net/vsl7niu.css");
%font-rustica {
    font: {
        family: 'rustica', sans-serif; 
    }
}
%font-mr-eaves {
    font: {
        family: "mr-eaves-xl-sans-narrow", sans-serif;
        style: normal;
        weight: 900;
        display: auto;
    }
}

/* Icons embed */
@font-face {
    font-family: 'EB-Marker';
    src: 
        url('../fonts/EB-Marker.eot?6tx7bb');
    src:  
        url('../fonts/EB-Marker.eot?6tx7bb#iefix') format('embedded-opentype'),
        url('../fonts/EB-Marker.ttf?6tx7bb') format('truetype'),
        url('../fonts/EB-Marker.woff?6tx7bb') format('woff'),
        url('../fonts/EB-Marker.svg?6tx7bb#EB-Marker') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
%font-eb-marker {
    font: {
        /* use !important to prevent issues with browser extensions that change fonts */
        family: 'EB-Marker','rustica' !important; 
        style: normal;
        weight: normal;
        display: block;
    }
}
  
[class^="icon-"], [class*=" icon-"] {
    @extend %font-eb-marker;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
.icon-single-family:before {
    content: "\e902";
}

.icon-mixed-use:before {
    content: "\e909";
}

.icon-special-purpose:before {
    content: "\e999";
}

.icon-lease:before {
    content: "\e9ed";
}

.icon-retail:before {
    content: "\e9fc";
}

.icon-location:before {
    content: "\ea38";
}

.icon-map4:before {
    content: "\ea48";
}

.icon-enlarge7:before {
    content: "\eb48";
}

.icon-shrink7:before {
    content: "\eb49";
}

.icon-industrial:before {
    content: "\eb67";
}

.icon-land:before {
    content: "\ebd2";
}

.icon-cross:before {
    content: "\ed6c";
}

.icon-facebook:before {
    content: "\eeef";
}

.icon-instagram:before {
    content: "\eef1";
}

.icon-twitter:before {
    content: "\eef5";
}

.icon-youtube:before {
    content: "\eefc";
}

.icon-linkedin2:before {
    content: "\ef29";
}

.icon-Equal_Housing_Lender:before {
    content: "\e903";
}

.icon-FDIC_Member:before {
    content: "\e904";
}

.icon-list:before {
    content: "\e900";
}

.icon-card:before {
    content: "\e901";
}

.icon-chevron-left:before {
    content: "\f053";
}

.icon-chevron-right:before {
    content: "\f054";
}

.icon-angle-left:before {
    content: "\f104";
}

.icon-angle-right:before {
    content: "\f105";
}

.icon-multifamily:before {
    content: "\f1ad";
}
